import {
  type Dispatch,
  type MutableRefObject,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { type AxiosResponse } from 'axios'

import {
  type FacetResult,
  type SearchResult,
  type SearchResults,
  type constraintsType,
  type searchKeyWordArgs,
} from '@blaw/contracts-api-schema'

import usePagination, { PaginationComponent, PaginationMeta } from '@hooks/usePagination'
import ApiClient from '@modules/ApiClient'
import { FacetLabel, FacetListItem, FilterState } from '@modules/ClauseAnalyzer'
import Contract, { ContractResourceWithDetails } from '@modules/Contract'
import { DEFAULT_NUM_ITEMS_PER_PAGE, addToArray, removeFromArray } from '@modules/utils'
import { StoreContext } from './StoreContext'
import { useDebounce } from '@hooks/useDebounce'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import { useTranslation } from '@hooks/useTranslation'
import { searchInputDelay } from '@modules/defaults'
import { getUsers, UserInfo } from '@modules/GetUsers'

interface Props {
  children?: any
  fetchingItemsDefault?: boolean
}

const sortOrder = [
  'filterOwner',
  'docType',
  'filterStatus',
  'filterIndustry',
  'filterGoverningLaw',
  'filterParty',
  'filterLawFirm',
  'filterYear',
]

const filterType = 'Contract'
const title = 'Filter Contracts'
const path = '/contracts'

export interface ContractsContextState {
  query: string
  setQuery: Dispatch<SetStateAction<string>>
  error?: string
  setError: Dispatch<SetStateAction<string | undefined>>
  warn: string
  isDocCardExpanded: boolean
  setIsDocCardExpanded: Dispatch<SetStateAction<boolean>>
  Pagination: PaginationComponent
  pageNum: number
  topOfPageRef: MutableRefObject<HTMLDivElement | null>
  filterType: string
  title: string
  path: string
  sortOrder: string[]
  facetLabels: FacetLabel
  cancelFilters: () => void
  filterState: FilterState
  handleFiltering: (facetName: string, facetItem: string, checked: boolean) => void
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  numFilters: () => number
  contracts: Contract[]
  setContracts: Dispatch<SetStateAction<Contract[]>>
  items: ContractsResponse
  fetchContracts: (query: string, page: number, filters: FilterState) => Promise<ContractsResult>
  loadPage: (query: string) => void
  loadNextPage: (page: number) => void
  loadFirstPage: (...args: any) => Promise<void>
  search: (query: string) => void
  fetchingItems: MutableRefObject<boolean>
  getFacetOptions: (field: string) => { key: string; text: string }[]
  users: UserInfo[]
  loadingUsers: boolean
}

type ContractsResult = {
  contracts: Contract[]
  meta: PaginationMeta
  items: {
    facets: FacetResult[]
    count: number
  }
  warn?: string
  error?: string
}

type ContractsResponse = {
  facets: FacetListItem[]
  count: number
}

const contractsPerPage = 20

export const ContractsContext = createContext({} as ContractsContextState)

function ContractsContextProvider(props: Props) {
  const [query, setQuery] = useState('')
  const [error, setError] = useState<string>()
  const [warn, setWarn] = useState('')
  const [prevFilterState, setPrevFilterState] = useState<FilterState>({})
  const [filterState, setFilterState] = useState<FilterState>({})
  const [loading, setLoading] = useState(true)
  const [contracts, setContracts] = useState<Contract[]>([])
  const [, setMeta] = useState<PaginationMeta>()
  const [facetLabels, setFacetLabels] = useState<FacetLabel>({})
  const [facets, setFacets] = useState<FacetListItem[]>([])
  const [count, setCount] = useState<number>(0)
  const {
    storeSessionInfo,
    routes: { searchUrl },
  } = useContext(StoreContext)
  const { metadataConfig, loadingMetadataConfig } = useContext(KeyTermsContext)
  const [users, setUsers] = useState<UserInfo[]>([])
  const [loadingUsers, setLoadingUsers] = useState(true)
  const apiClient = new ApiClient(storeSessionInfo, setError)
  const { t } = useTranslation()
  const fetchingItems = useRef(props.fetchingItemsDefault ?? false)

  const [isDocCardExpanded, setIsDocCardExpanded] = useState(false)

  const { loadFirstPage, Pagination, pageNum } = usePagination({
    loadPage,
    loadNextPage,
    hitsPerPage: contractsPerPage,
    scrollToTop: () => topOfPageRef.current && topOfPageRef.current.scrollIntoView(),
  })

  const debouncedLoad = useDebounce(loadFirstPage, searchInputDelay, [])
  const debouncedSetFilter = useDebounce(setFilterState, searchInputDelay, [])
  const topOfPageRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    if (JSON.stringify(filterState) !== JSON.stringify(prevFilterState)) {
      loadFirstPage(query)
      setPrevFilterState({ ...filterState })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState])

  useEffect(() => {
    debouncedSetFilter({})
    debouncedLoad(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    if (!loadingMetadataConfig && metadataConfig) {
      const ownerFacet = query.length ? 'parent_resource_owner' : 'owner'
      setFacetLabels({
        [ownerFacet]: metadataConfig.getLabel(ownerFacet),
        party_name: metadataConfig.getLabel('party_name'),
        contract_type: metadataConfig.getLabel('contract_type'),
        contract_status: metadataConfig.getLabel('contract_status'),
        company_stakeholder: metadataConfig.getLabel('company_stakeholder'),
        client_name: metadataConfig.getLabel('client_name'),
        batch_job_data: metadataConfig.getLabel('batch_job_data'),
      })
    }
  }, [loadingMetadataConfig, metadataConfig, query])

  useEffect(() => {
    getUsers()
      .then(value => {
        setUsers(value)
      })
      .catch(e => {
        console.error(e)
        setError(e)
      })
      .finally(() => setLoadingUsers(false))
  }, [])

  async function fetchContracts(query: string, page: number, filters: FilterState) {
    const result: ContractsResult = {
      contracts: [],
      meta: { totalPages: 0 },
      items: { facets: [], count: 0 },
    }
    filters = Object.fromEntries(Object.entries(filters).filter(([_, v]) => v.length))
    const params: searchKeyWordArgs = {
      userQuery: query,
      systemQuery: [],
      facetMaxValuesPerFacet: 1000,
      facetSortOrder: 'COUNT_DESCENDING',
      context: query ? 'document' : 'contract',
      sortField: { fieldName: query ? 'relevance' : 'last_updated_date', Direction: 'desc' },
      maxDigests: 0,
      facetsResultType: 'BY_NAME',
      offset: page * DEFAULT_NUM_ITEMS_PER_PAGE,
      maxResults: DEFAULT_NUM_ITEMS_PER_PAGE,
      facets: Object.entries(filters).map(
        entry => ({ key: entry[0], value: entry[1] } as constraintsType),
      ),
      ranges: [],
      fetchFields: [],
      facetsNames: [],
      constraints: [],
    }

    try {
      const {
        data: { results, facets, totalHits },
      } = (await apiClient.post(searchUrl, params)) as AxiosResponse<SearchResults>

      const meta = {
        totalPages: Math.floor(totalHits / DEFAULT_NUM_ITEMS_PER_PAGE),
        totalHits: totalHits,
      }

      if (results?.length) {
        const contracts = results.map(searchResultToContract)
        setContracts(contracts)
        setMeta(meta)
        const newFacets = facets.map(
          ({ name, values, type }) =>
            ({
              field: name,
              values: values.map(({ label, value, count }) => ({ label, value, count, type })),
            } as FacetListItem),
        )
        setFacets(newFacets)
        setCount(totalHits)
        result.contracts = contracts
        result.meta = meta
      } else {
        setCount(0)
        result.warn = t('page.Contracts.No Results Warning')
      }
    } catch (e) {
      console.error(e)
      result.error = (e as Error).message
    }
    return result
  }

  function searchResultToContract(searchResult: SearchResult): Contract {
    const { metadata } = searchResult
    return new Contract({
      contractId: searchResult.parentId ? searchResult.parentId : searchResult.documentId,
      contractTitle: searchResult.documentName,
      createdBy: searchResult.modifiedBy || metadata.owner,
      dateModified: new Date(metadata.last_updated_date?.value || ''),
      dateCreated: new Date(searchResult.dateCreated),
      metadata: {
        userMetadata: {
          contract_status: metadata.contract_status?.value,
          contract_type: metadata.contract_type?.value,
        },
        customMetadata: {},
        systemMetadata: {},
      },
      digests: searchResult.digests,
      mimeType: searchResult.mimeType,
      parentName: searchResult.parentName,
      owner: metadata.owner?.value,
      accessControls: {
        readList: metadata.read_groups?.value,
        writeList: metadata.write_groups?.value,
        deleteList: metadata.delete_groups?.value,
        forbiddenList: metadata.forbidden_groups?.value,
      },
    } as ContractResourceWithDetails)
  }

  async function loadPage(query: string) {
    setError('')
    setWarn('')
    setLoading(true)
    fetchingItems.current = true

    const { contracts, meta, warn, error } = await fetchContracts(query, 0, filterState)

    fetchingItems.current = false
    setLoading(false)
    if (warn) setWarn(warn)
    if (error) setError(error)
    if (!contracts.length) return {}

    return meta
  }

  function search(query: string) {
    setIsDocCardExpanded(true)
    setQuery(query)
    debouncedLoad(query)
  }

  async function loadNextPage(page: number) {
    setError('')
    setWarn('')
    const { contracts: newContracts, warn, error } = await fetchContracts(query, page, filterState)

    if (warn) setWarn(warn)
    if (error) setError(error)

    setContracts([...contracts, ...newContracts])
  }

  function handleFiltering(facetName: string, facetItem: string, checked: boolean) {
    setFilterState(prevState => {
      let previousFacetName = prevState[facetName]
      if (!previousFacetName) {
        previousFacetName = []
      }
      return {
        ...prevState,
        [facetName]: checked
          ? addToArray(previousFacetName, facetItem)
          : removeFromArray(previousFacetName, facetItem),
      }
    })
  }

  function cancelFilters() {
    setFilterState({})
  }

  function numFilters() {
    return Object.values(filterState).reduce(
      (prevValue, filterArray) => prevValue + filterArray.length,
      0,
    )
  }

  function getFacetOptions(field: string) {
    return (
      facets
        .find(f => f.field === field)
        ?.values.map((item, i) => ({ key: i.toString(), text: item.value })) || []
    )
  }

  const value = {
    query,
    setQuery,
    error,
    setError,
    warn,
    isDocCardExpanded,
    setIsDocCardExpanded,
    Pagination,
    pageNum,
    topOfPageRef,
    filterType,
    title,
    path,
    sortOrder,
    facetLabels,
    cancelFilters,
    filterState,
    handleFiltering,
    loading,
    setLoading,
    numFilters,
    contracts,
    setContracts,
    items: { facets, count },
    fetchContracts,
    loadPage,
    loadNextPage,
    loadFirstPage,
    search,
    fetchingItems,
    getFacetOptions,
    users,
    loadingUsers,
  }

  return <ContractsContext.Provider value={value}>{props.children}</ContractsContext.Provider>
}

export default ContractsContextProvider
