import { IContextualMenuItem, List, NeutralColors, mergeStyleSets } from '@fluentui/react'

import { type Resource } from '@blaw/contracts-api-schema'
import { StyledDividerWithoutMargin } from '@baseComponents/StyledDivider'
import LoadingShimmer from '@components/LoadingShimmer'
import QuickMessage from '@components/QuickMessage'
import ActionsMenu from '@components/ActionsMenu'
import { friendlyDateTime } from '@modules/utils'
import useDocumentOpen from '@hooks/useDocumentOpen'
import DocumentOpenStatus from '@components/DocumentOpenStatus'
import ResourceListHeadings from '@components/ResourceListHeadings'
import HighlightedText from '@baseComponents/HighlightedText'
import { useTranslation } from '@hooks/useTranslation'
import BoldField from '@components/BoldField'
import { CSSProperties } from 'react'
import { useNavigate } from 'react-router-dom'
import ResourceTitle from '@components/ResourceTitle'
import { DISPLAYED_RESOURCETYPES } from '@src/data/displayedResourceTypes'
import AccessControl from '@modules/AccessControl'
import { defaultActionsMenuItems } from '@src/data/defaultActionsMenuItems'

type Props = {
  resources: Resource[]
  access: AccessControl
  loading?: boolean
  label?: string
  error?: string | null
  style?: CSSProperties
  onRenderResource?: (item?: Resource) => JSX.Element | null
}

const templateItems = [
  {
    key: 'createContract',
    text: 'Create Contract',
    iconProps: { iconName: 'TextDocument' },
  },
]

export default function TemplatesList({
  loading,
  label,
  error,
  resources,
  access,
  style,
  onRenderResource,
}: Props) {
  const { loadingDocument, openDocumentError, setOpenDocumentError, openDocumentHandler } =
    useDocumentOpen()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const renderResource = onRenderResource ?? defaultRenderResource

  if (loading) return <LoadingShimmer />
  if (error) return <QuickMessage msg={error} type="error" />
  if (!resources.length) return <QuickMessage msg={`0 ${label}s found`} type="warning" />

  return (
    <div>
      {label && <ResourceListHeadings label={label} />}
      <List
        onShouldVirtualize={() => false}
        items={resources}
        onRenderCell={item => renderResource(item)}
      />
      <DocumentOpenStatus
        loadingMessage="Opening Document..."
        loadingDocument={loadingDocument}
        openDocumentError={openDocumentError}
        setOpenDocumentError={setOpenDocumentError}
        clearErrorDelay={10000}
      />
    </div>
  )

  async function onTemplateItemClick(item: Resource, action: IContextualMenuItem) {
    // This depends on the type of resource, for contracts primary = contract id and secondary
    // document id, for templates both are template id since there's no parent/child
    const primary_id = item.parent ? item.parent : item.id
    const secondary_id = item.id

    switch (action.key) {
      case 'viewInWeb':
        openDocumentHandler(secondary_id, primary_id, item.mimeType, undefined, item.resourceType)
        break
      case 'editInWord':
        openDocumentHandler(
          secondary_id,
          primary_id,
          item.mimeType,
          undefined,
          item.resourceType,
          true,
        )
        break
      case 'createContract':
        navigate('/contracts/new', { state: { template: item } })
        break
      default:
        throw new Error(`Invalid action "${action.key}"`)
    }
  }

  function defaultRenderResource(item?: Resource) {
    if (!item) return null
    if (!DISPLAYED_RESOURCETYPES.includes(item.resourceType)) return null
    return (
      <>
        <div
          style={{
            padding: '0.5em 0 1em 0',
            ...style,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <ResourceTitle item={item} />

            {access.canEditTemplate() && (
              <ActionsMenu
                items={defaultActionsMenuItems.concat(templateItems)}
                onItemClick={(action: IContextualMenuItem) => onTemplateItemClick(item, action)}
                iconStyles={{
                  border: `1px solid ${NeutralColors.gray60}`,
                  borderRadius: '0.2em',
                  padding: '0.1em 0.2em',
                }}
              />
            )}
          </div>

          {item.metadata?.userMetadata?.contract_type && (
            <BoldField
              label={`${t('label.contract-type')}: `}
              value={item.metadata.userMetadata.contract_type}
            />
          )}
          {item.metadata?.userMetadata?.description && (
            <BoldField
              label={`${t('label.description')}: `}
              value={item.metadata.userMetadata.description}
            />
          )}
          {item.author && (
            <BoldField
              label={`${t('label.ContractMetadata.last-modified')}: `}
              value={
                item.updated
                  ? `${friendlyDateTime(item.updated)} by ${item.author}`
                  : `${item.author}`
              }
            />
          )}
          <HighlightedText
            text={item?.metadata?.customMetadata?.digests?.[0]}
            title={t('label.keyword-match')}
          />
        </div>
        <StyledDividerWithoutMargin />
      </>
    )
  }
}
